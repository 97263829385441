import React, {useState} from "react";
import {connect} from "react-redux";
import withNavigation from "./higher-order/navigation";
import "../../css/main-menu.css";
import * as navigation from "../constants/navigation-constants";
import colorsConstant from "../constants/colors.json";
import Translator from "./translator";

const {SELECTED_COLOR, UNSELECTED_COLOR} = colorsConstant;

function mapStateToProps(state, ownProps) {
    return {
        parametresWeb: state.parametresWeb,
        configuration: state.configuration,
    };
}

function MainMenu(props) {
    if (!props.configuration.affichageIframe || props.configuration.affichageIframe === "True") return <div/>;

    const TEXTS = Translator(props.match.params.language, "TXT_MAIN_MENU");
    const isDevSite = window.location.hostname.includes('dev.') || window.location.hostname.includes('localhost');
    const pathName = props.getUrlExcludingInitialParameters();
    const items = getMenuItems(props.parametresWeb || {}, props.configuration || {});

    function getMenuItems(parametresWeb, configuration) {
        const items = [];

        if (parametresWeb.reservationEnLigne) {
            items.push({
                path: navigation.PATH_RESERVATION,
                title: TEXTS.title0,
                match: /\/$|\/reservation$|\/choix-unite\/\S*/
            });
        }

        if (isDevSite && parametresWeb.venteForfaitEnLigne) {
            items.push({
                path: navigation.PATH_ACHAT_FORFAIT,
                title: TEXTS.title1
            });
        }

        if (isDevSite && configuration.activerTirageEnLigne === "True" || (configuration.lienTirage48hsurPav || '').length > 0) {
            items.push({
                path: navigation.PATH_TIRAGE,
                title: TEXTS.title2
            }); // Je ne sais pas laquelle des deux configurations est la bonne. Je mets les deux pour l'instants.
        }

        if (isDevSite && parametresWeb.enregistrementEnLigne) {
            items.push({
                path: navigation.PATH_DROIT_ACCES,
                title: TEXTS.title3
            });
        }

        if (isDevSite && parametresWeb.paiementFactureEnLigne || parametresWeb.paiementReservationEnLigne) {
            items.push({
                path: navigation.PATH_PAIEMENT_COMPTES,
                title: TEXTS.title8
            });
        }
        if (parametresWeb.fermerEnregistrement) {
            items.push({
                path: navigation.PATH_FERMER_ENREGISTREMENT,
                title: TEXTS.title10
            });
        }

        if (configuration.activerBoutiqueEnLigne === "True") {
            items.push({path: navigation.PATH_BOUTIQUE, title: TEXTS.title4});
        }

        if (configuration.activerBilletterieEnLigne === "True") items.push({
            path: navigation.PATH_BILLETTERIE,
            title: TEXTS.title9
        });
        items.push({
            path: navigation.PATH_PANIER,
            title: TEXTS.title5
        });

        return items;
    }

    return (
        <div id="main-menu">
            <div id="main-menu-center">
                { items.map((item, key) => {
                    const isMatch = item.match === undefined
                        ? pathName === item.path
                        : pathName.match(item.match);

                    return (
                        <div className="main-menu-item-wrapper" key={ key }>
              <span
                  style={ {color: isMatch ? SELECTED_COLOR : UNSELECTED_COLOR} }
                  onClick={ () => props.goTo(item.path) }>
                { item.title }
              </span>
                        </div>
                    )
                }) }
            </div>
        </div>
    );
}

export default withNavigation(connect(mapStateToProps)(MainMenu));